<template>
	<div>
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link :to="{name: 'languages-index'}">Languages</router-link>
				</li>
			</ol>
		</nav>
		<language-form v-model="model"></language-form>
		<button class="btn btn-primary" @click="save">SAVE</button>
	</div>
</template>

<script>
import LanguageForm from "../../components/languages/language-form";
import api from '@/api'

export default {
	name: "new",
	components: {LanguageForm},
	data: () => ({
		model: {
			Name: '',
			DemoUrl: '',
			Enabled: true,
			Assets: {},
			Label: {
				Enabled: false,
				Text: "",
				BackgroundColor: "",
				TextColor: ""
			}
		}
	}),
	methods: {
		async save() {
			let language = await api.Localizations.Languages.saveNew(this.model)

			this.$router.replace({
				name: 'languages-id',
				params: {
					id: language.Id
				}
			})

		}
	}
}
</script>

<style scoped>

</style>
